import { ContentContext } from '@components/layouts'
import React, { useContext } from 'react'

const Header = () => {
  const { lang, translate } = useContext(ContentContext)

  return (
    <section className="wrapper bg-light" id="home">
      <div className="container-card">
        <div
          className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-light-500 mt-2 mb-5"
          data-image-src="/bg22.png"
        >
          <div className="card-body py-14 px-0">
            <div className="container">
              <div className="row gx-md-8 gx-xl-12 gy-10 align-items-center text-center text-lg-start">
                <div
                  className="col-lg-6"
                  data-cues="slideInDown"
                  data-group="page-title"
                  data-delay="900"
                >
                  {lang === 'en' ? (
                    <h1 className="display-2 mb-4 me-xl-5 me-xxl-0">
                      {translate('moto_path1')}{' '}
                      <span className="text-gradient gradient-1">
                        {translate('moto_solution')}
                      </span>
                    </h1>
                  ) : (
                    <h1 className="display-2 mb-4 me-xl-5 me-xxl-0">
                      {translate('moto_path1')}{' '}
                    </h1>
                  )}
                  <p className="lead fs-23 lh-sm mb-7 pe-xxl-15">
                    {translate('moto_sub')}
                  </p>
                  <a
                    href="#services"
                    className="btn btn-lg btn-gradient gradient-1 rounded"
                  >
                    {translate('services')}
                  </a>
                </div>
                {/* <!--/column --> */}
                <div className="col-lg-6">
                  <img
                    className="img-fluid mb-n18"
                    src="/3d6.png"
                    srcSet="/3d6@2x.png 2x"
                    data-cue="fadeIn"
                    data-delay="300"
                    alt=""
                  />
                </div>
                {/* <!--/column --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container --> */}
          </div>
          {/* <!--/.card-body --> */}
        </div>
        {/* <!--/.card --> */}
      </div>
      {/* <!-- /.container-card --> */}
    </section>
  )
}

export default Header
