import { ContentContext } from '@components/layouts'
import React, { useContext, useEffect } from 'react'
import { FaGlobeAfrica } from '@react-icons/all-files/fa/FaGlobeAfrica'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import toast, { Toaster } from 'react-hot-toast'
import { Link } from 'gatsby'

interface Props {
  postImage: string
  title: string
  at: string
  postID: string
  postLink: string
}

const BlogHomePopularPostCard: React.FC<Props> = ({
  postImage,
  title,
  at,
  postID,
  postLink
}) => {
  const { lang, translate, setLanguage } = useContext(ContentContext)

  return (
    <Link to={`/blog/${lang}/${postID}`}>
      <div className="col card h-100">
        <figure style={{
            height: '50%'
          }}>
          <img src={postImage} alt="" className="rounded-top" style={{
              minHeight: '100%',
              maxHeight: '100%',
              objectFit: 'cover'
            }} />
        </figure>
        <div className="card-body px-3 py-1">
          <h6>{title}</h6>
        </div>
        <div className="card-footer p-3 border-top-0">
          <ul className="post-meta">
            <li className="post-date">
              <i className="uil uil-calendar-alt"></i>
              <span>{at}</span>
            </li>
          </ul>
        </div>
      </div>
    </Link>
  )
}

export default BlogHomePopularPostCard
