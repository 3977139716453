import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Landing from '../Landing'
import Layout from '../layouts'

function LandingLayout() {
  const popularArticlesHomeQuery = useStaticQuery(graphql`
    query NanodevBlogPopularArticlesHome {
      popularPosts: allStrapiNanodevBlog(
        limit: 4
        filter: { post_likes_amt: { gte: 5 } }
      ) {
        nodes {
          post_title
          post_at
          post_id
          post_link
          post_image {
            formats {
              small {
                url
              }
            }
          }
          localizations {
            data {
              attributes {
                locale
                post_title
              }
              id
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Landing data={popularArticlesHomeQuery.popularPosts.nodes} />
    </Layout>
  )
}

export default LandingLayout
