import React, {
  ReactElement,
  useContext,
  useState,
} from 'react'
import { Script, ScriptStrategy } from 'gatsby'
import LandingLayout from '../layouts/LandingLayout'

const TAG_ID = 'G-C58XL6GFYS'

const MainLayout = ({ children }: { children: ReactElement }) => {
  const isBrowser = typeof window !== 'undefined' ? true : false
  isBrowser ? document.body.setAttribute('data-bs-spy', 'scroll') : null
  const [pluginsLoaded, setPluginsLoaded] = useState(false)
  const [jqueryLoaded, setJQueryLoaded] = useState(false)
  const [svgInjectLoaded, setSvgInjectLoaded] = useState(false)
  
  return (
    <>

      <LandingLayout />
        <Script async src={`https://www.googletagmanager.com/gtag/js?id=${TAG_ID}`}></Script>
        <Script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)};
          gtag('js', new Date());

          gtag('config', '${TAG_ID}');`}
        </Script>

      {isBrowser && (
        <Script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          onLoad={() => setJQueryLoaded(true)}
        />
      )}

      {jqueryLoaded && isBrowser && (
        <Script
          src="/plugins.js"
          key={'pluginjs'}
          strategy={ScriptStrategy.postHydrate}
          onLoad={() => setPluginsLoaded(true)}
        />
      )}

      {jqueryLoaded && isBrowser && (
        <Script
          src="/svg-inject.min.js"
          key={'svg-inject'}
          onLoad={() => setSvgInjectLoaded(true)}
        />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/headhesive.min.js" key={'headhesive'} />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/scrollCue.min.js" key={'scrollCue'} />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/glightbox.min.js" key={'glightbox'} />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/owl.carousel.min.js" key={'owl.carousel'} />
      )}
      {svgInjectLoaded && isBrowser && <Script src="/theme.js" key={'theme'} />}
    </>
  )
}

export default MainLayout
