import { ContentContext } from '@components/layouts'
import {
  CreativeGuildLogo,
  LavalLogo,
  RandstadLogo,
  RBCLogo,
  Autodesk,
  Fresenius_Medical_Care,
  Veritaaq,
  GigaSite
} from '@images/images'
import React, { useContext } from 'react'

const Clients = () => {
  const { lang, translate, setLanguage } = useContext(ContentContext)

  return (
    <div className="container pb-14 pb-md-16" id="projects">
      <div className="row text-center" id="services">
        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">
            <b>{translate('projects')}</b>
          </h2>
        </div>
        {/* <!-- /column --> */}
      </div>
      <div className="position-relative">
        <div
          className="shape rounded-circle bg-soft-yellow rellax w-16 h-16"
          data-rellax-speed="1"
          style={{ bottom: '0.5rem', right: '-1.7rem' }}
        ></div>
        <div
          className="shape bg-dot primary rellax w-16 h-16"
          data-rellax-speed="1"
          style={{ top: '-1rem', left: '-1.7rem' }}
        ></div>
        <div
          className="carousel owl-carousel gap-small"
          data-margin="0"
          data-dots="true"
          data-autoplay="false"
          data-autoplay-timeout="5000"
          data-responsive='{"0":{"items": "1"}, "768":{"items": "2"}, "992":{"items": "2"}, "1200":{"items": "3"}}'
        >
          {/* <!-- /.item --> */}
          <div className="item w-100">
            <div className="item-inner">
              <div className="card custom-card-shadow ">
                <div className="card-body">
                  <blockquote className="mb-0">
                    <img
                      src={CreativeGuildLogo}
                      alt={`telus-logo`}
                      style={{
                        height: '70px',
                        margin: '0 auto'
                      }}
                    />
                  </blockquote>
                </div>
                {/* <!-- /.card-body --> */}
              </div>
              {/* <!-- /.card --> */}
            </div>
            {/* <!-- /.item-inner --> */}
          </div>
          {/* <!-- /.item --> */}
          {/* <!-- /.item --> */}
          <div className="item w-100">
            <div className="item-inner">
              <div className="card custom-card-shadow ">
                <div className="card-body">
                  <blockquote className="mb-0">
                    <img
                      src={Autodesk}
                      alt={`AutoDesk-logo`}
                      style={{
                        height: '70px',
                        width: 'auto',
                        margin: '0 auto'
                      }}
                    />
                  </blockquote>
                </div>
                {/* <!-- /.card-body --> */}
              </div>
              {/* <!-- /.card --> */}
            </div>
            {/* <!-- /.item-inner --> */}
          </div>
          {/* <!-- /.item --> */}
          <div className="item w-100">
            <div className="item-inner">
              <div className="card custom-card-shadow">
                <div className="card-body">
                  <blockquote className="mb-0">
                    <img
                      src={RBCLogo}
                      alt={`rbc-bank-logo`}
                      style={{
                        height: '70px',
                        width: 'auto',
                        margin: '0 auto'
                      }}
                    />
                  </blockquote>
                </div>
                {/* <!-- /.card-body --> */}
              </div>
              {/* <!-- /.card --> */}
            </div>
            {/* <!-- /.item-inner --> */}
          </div>

          {/* <!-- /.item --> */}
          <div className="item w-100">
            <div className="item-inner">
              <div className="card custom-card-shadow">
                <div className="card-body">
                  <blockquote className="mb-0">
                    <img
                      className="freClient"
                      src={Fresenius_Medical_Care}
                      alt={`Fresenius-Medical-Care-logo`}
                      style={{
                        height: '70px',
                        margin: '0 auto'
                      }}
                    />
                  </blockquote>
                </div>
                {/* <!-- /.card-body --> */}
              </div>
              {/* <!-- /.card --> */}
            </div>
            {/* <!-- /.item-inner --> */}
          </div>

          {/* <!-- /.item --> */}
          <div className="item w-100">
            <div className="item-inner">
              <div className="card custom-card-shadow">
                <div className="card-body">
                  <blockquote className="mb-0">
                    <img
                      src={RandstadLogo}
                      alt={`randstad-logo`}
                      style={{
                        height: '70px',
                        margin: '0 auto',
                      }}
                    />
                  </blockquote>
                </div>
                {/* <!-- /.card-body --> */}
              </div>
              {/* <!-- /.card --> */}
            </div>
            {/* <!-- /.item-inner --> */}
          </div>
          {/* <!-- /.item --> */}

          {/* <!-- /.item --> */}
          <div className="item w-100">
            <div className="item-inner">
              <div className="card custom-card-shadow">
                <div className="card-body">
                  <blockquote className="mb-0">
                    <div style={{height: '70px', display: 'flex', alignItems: 'center'}}>
                      <img
                        src={GigaSite}
                        alt={`giga-site-logo`}
                        style={{
                          height: '1.8rem',
                          margin: '0 auto',
                        }}
                      />
                    </div>
                  </blockquote>
                </div>
                {/* <!-- /.card-body --> */}
              </div>
              {/* <!-- /.card --> */}
            </div>
            {/* <!-- /.item-inner --> */}
          </div>
          {/* <!-- /.item --> */}

        </div>
        {/* <!-- /.owl-carousel --> */}
      </div>
    </div>
  )
}

export default Clients
