import { ContentContext } from '@components/layouts'
import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import toast, { Toaster } from 'react-hot-toast'
import './style.css'

interface Inputs {
  name: string
  usermessage: string
  email: string
  lang: string
}

const Contact = () => {
  const { translate, lang } = useContext(ContentContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isFormSend, setIsFormSend] = useState(false)

  const schema = object().shape({
    name: string()
      .typeError(translate('input_type_error'))
      .required(translate('required_input')),
    email: string()
      .email()
      .typeError(translate('input_type_error'))
      .required(translate('required_input')),
    usermessage: string()
      .typeError(translate('input_type_error'))
      .required(translate('required_input'))
  })

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const send = (formData: Inputs) => {
    formData.lang = lang

    setIsLoading(true)
    let url =
      'https://us-central1-internal-nanodev.cloudfunctions.net/contactUs'
    fetch(url, {
      mode: 'no-cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(r => {
        setIsLoading(false)
        toast.success(translate('successfully_send'))
        setIsFormSend(true)
        reset()
      })
      .catch(e => {
        setIsLoading(false)
        setIsFormSend(false)
        toast.error(translate('an_error_occured'))
      })
  }

  const Form = () => (
    <form
      className="contact-form needs-validation"
      onSubmit={handleSubmit(send)}
    >
      <div className="messages"></div>
      <div className="row gx-4">
        <div className="col-md-6">
          <div className=" mb-4">
            <label htmlFor="form_name">{translate('name')} *</label>
            <input
              id="form_name"
              type="text"
              className={
                'form-control border-0' + (errors.name && ` is-border-red`)
              }
              placeholder={translate('name')}
              {...register('name')}
            />
            <div className="invalid-feedback">
              {translate('required_name_message')}
            </div>
          </div>
        </div>
        {/* <!-- /column --> */}
        <div className="col-md-6">
          <div className=" mb-4">
            <label htmlFor="form_email">{translate('email_address')}</label>
            <input
              id="form_email"
              type="email"
              className={
                'form-control border-0' + (errors.email && ` is-border-red`)
              }
              placeholder={translate('email_address')}
              {...register('email')}
            />
            {errors?.email?.type === 'email' ? (
              <div className="invalid-feedback">
                {translate('input_type_error')}
              </div>
            ) : (
              <div className="invalid-feedback">
                {translate('required_email_message')}
              </div>
            )}
          </div>
        </div>
        {/* <!-- /column --> */}
        <div className="col-12">
          <div className=" mb-4">
            <label htmlFor="form_message">{translate('message')}</label>
            <textarea
              id="form_message"
              className={
                'form-control border-0' +
                (errors.usermessage && ` is-border-red`)
              }
              placeholder={translate('message')}
              style={{ height: '150px' }}
              {...register('usermessage')}
            ></textarea>
            {errors.usermessage && (
              <div className="invalid-feedback">
                {translate('required_message_message')}
              </div>
            )}
          </div>
        </div>
        {/* <!-- /column --> */}
        <div className="col-12">
          {isLoading ? (
            <button className="btn rounded-pill btn-primary mb-3" type="button">
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-outline-primary rounded-pill btn-send mb-3"
              disabled={isSubmitting}
            >
              {translate('send')}
            </button>
          )}
        </div>
        {/* <!-- /column --> */}
      </div>
      {/* <!-- /.row --> */}
    </form>
  )

  return (
    <>
      <Toaster />
      <div className="container-card" id="contact-us">
        <div
          className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-light-500 mb-0"
          data-image-src="/bg22.png"
        >
          <div className="card-body py-5 px-lg-12 px-md-12 px-1">
            <div className="container">
              <div className="row text-center">
                <div className="col-xl-11 col-xxl-9 mx-auto">
                  <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3 font-weight-bold">
                    {translate('contact_us')}
                  </h2>
                </div>
              </div>
              <div className="row gx-md-8 gx-xl-12 gy-10">
                <div className="col-lg-6 col-sm-12">
                  <img
                    src="/email.svg"
                    className="icon-svg icon-svg-sm mb-4"
                    alt=""
                  />
                  <h2 className="display-4 mb-3 pe-lg-10 text-left">
                    {translate('contact_us_message')}
                  </h2>
                  <p className="lead pe-lg-12 mb-0 text-justify">
                    {translate('contact_us_description')}
                  </p>
                </div>
                <div className="col-lg-6 col-sm-12">
                  {isFormSend ? (
                    <>
                      <p id="respond-text">{translate('contact_message')}</p>
                    </>
                  ) : (
                    <Form />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
