import React, { UIEvent, useEffect, useState, useContext } from 'react'
import { ContentContext } from '../components/layouts'
import Header from './header'
import Nav from './nav'
import Footer from './footer'
import Clients from './projects'
import Contact from './contact'
import BlogCard from './blogCard'
import { popularArticlesHome_GET } from '../services/api-Calls/popularArticlesHome_GET'
import BlogHomePopularPostCard from './blogHomePopularPostCard'
import { graphql, useStaticQuery } from 'gatsby'

function Landing({ data }: { data: any }) {
  const { lang, translate, setLanguage } = useContext(ContentContext)
  const [blogPopularPosts, setBlogPopularPosts] = useState<Array<JSX.Element>>(
    []
  )

  const populatePopularBlogPosts = async () => {
    try {
      let tempVar: JSX.Element[] = []
      if (data.length >= 1) {
        data.forEach((element: any, x: number) => {
          tempVar[x] = (
            <BlogHomePopularPostCard
              postImage={element.post_image.formats.small.url}
              title={
                lang === 'en'
                    ? element.post_title
                    : lang === 'fr'
                      ? element.localizations.data[0]?.attributes.locale == 'fr' 
                      ? element.localizations.data[0]?.attributes.post_title 
                      : element.localizations.data[1]?.attributes.post_title
                      : lang === 'ar'
                        ? element.localizations.data[1]?.attributes.locale == 'ar' 
                        ? element.localizations.data[1]?.attributes.post_title 
                        : element.localizations.data[0]?.attributes.post_title
                        : element.post_title
              }
              at={element.post_at}
              postID={element.post_id}
              postLink={element.post_link}
            />
          )
        })
        setBlogPopularPosts(prev => tempVar)
      }
    } catch (err) {}
  }

  useEffect(() => {
    setBlogPopularPosts([])
    populatePopularBlogPosts()
  }, [lang])

  return (
    <>
      <div className="content-wrapper">
        <Nav />
        {/* <!-- /header --> */}
        <Header />
        {/* <!-- /section --> */}
        <section className="wrapper bg-light">
          <div className="container pt-14 pt-md-17 pb-0 pb-md-0">
            <div className="row text-center" id="services">
              <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">
                  <b>{translate('services')}</b>
                </h2>
                <h3 className="display-4 mb-9 px-xl-11">
                  {translate('services_head')}
                </h3>
              </div>
              {/* <!-- /column --> */}
            </div>
            {/* <!-- /.row --> */}
            <div className="row gy-8 mb-17">
              <div className="col-md-6 col-lg-6">
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src="/script.svg"
                      className="icon-svg icon-svg-sm solid-duo text-grape-fuchsia me-4"
                      alt=""
                    />
                  </div>
                  <div>
                    <h3 className="fs-26 mb-1">
                      {translate('software_and_integration')}
                    </h3>
                    <p className="mb-0 text-justify">
                      {translate('software_and_integration_description')}
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--/column --> */}
              <div className="col-md-6 col-lg-6">
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src="/verify.svg"
                      className="icon-svg icon-svg-sm solid-duo text-grape-fuchsia me-4"
                      alt=""
                    />
                  </div>
                  <div>
                    <h3 className="fs-26 mb-1">
                      {translate('artificial_intelligence')}
                    </h3>
                    <p className="mb-0 text-justify">
                      {translate('artificial_intelligence_description')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--/.row --> */}
            <div
              className="row gx-md-8 gy-10 mb-15 mb-md-18  align-items-center"
              id="about-us"
            >
              <div className="row text-center">
                <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                  <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">
                    <b>{`${translate('about_us')}`}</b>
                  </h2>
                  <p className="mb-9 px-xl-11">
                    {translate('about_us_description')}
                  </p>
                </div>
                {/* <!-- /column --> */}
              </div>
              <div className="col-lg-6 order-lg-2 position-relative">
                <figure className="rounded">
                  <img
                    className="img-fluid"
                    src="/3d2.png"
                    srcSet="/3d2@2x.png 2x"
                    alt=""
                  />
                </figure>
              </div>
              {/* <!--/column --> */}
              <div className="col-lg-5 offset-lg-1">
                <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">
                  {translate('vision')}
                </h2>
                <h3 className="display-4 mb-9">
                  {translate('vision_sub_title')}
                </h3>
                <p className="mb-6 text-justify">
                  {translate('vision_description')}
                </p>
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!--/.row --> */}
            <div
              className="row gx-3 gy-10 mb-15 mb-md-18 align-items-center"
              id="mission"
            >
              <div className="col-lg-5 offset-lg-1">
                <figure>
                  <img
                    className="w-auto"
                    src="/3d9.png"
                    srcSet="/3d9@2x.png"
                    alt=""
                  />
                </figure>
              </div>
              {/* <!--/column --> */}
              <div className="col-lg-5 offset-lg-1">
                <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">
                  {translate('mission')}
                </h2>
                <h3 className="display-4 mb-4 text-justify">
                  {translate('mission_sub_title')}
                </h3>
                <p className="mb-6 text-justify">
                  {translate('mission_description')}
                </p>
                {/* <!--/.row --> */}
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!--/.row --> */}

            <div
              className="row gx-3 gy-10 mb-15 mb-md-18 align-items-center"
              id="mission"
            >
              {/* <!--/column --> */}
              <div className="col-lg-5 offset-lg-1">
                <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">
                  {translate('value_title')}
                </h2>
                <h3 className="display-4 mb-4">
                  {translate('value_sub_title')}
                </h3>
                <p className="mb-6 text-justify">
                  {translate('value_description')}
                </p>
                <div className="row gy-3">
                  <div className="col-xl-12">
                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                      <li>
                        <span>
                          <i className="uil uil-check"></i>
                        </span>
                        <span>
                          <b>{translate('audacity_description')}</b>
                        </span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="uil uil-check"></i>
                        </span>
                        <span>
                          <b>{translate('respect_value_description')}</b>
                        </span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="uil uil-check"></i>
                        </span>
                        <span>
                          <b>{translate('share_value_description')}</b>
                        </span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="uil uil-check"></i>
                        </span>
                        <span>
                          <b>{translate('engagement_value_description')}</b>
                        </span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="uil uil-check"></i>
                        </span>
                        <span>
                          <b>{translate('innovation_value_description')}</b>
                        </span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="uil uil-check"></i>
                        </span>
                        <span>
                          <b>{translate('excellence_value_description')}</b>
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* <!--/column --> */}
                </div>
                {/* <!--/.row --> */}
              </div>
              <div className="col-lg-5 offset-lg-1">
                <figure>
                  <img
                    className="w-auto"
                    src="/3d5.png"
                    srcSet="/3d5@2x.png"
                    alt=""
                  />
                </figure>
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!--/.row --> */}
          </div>
          {/* <!-- /.container --> */}
        </section>
        {/* <!-- /section --> */}
      </div>
      {/* <!-- /.content-wrapper --> */}
      <Clients />
      <Contact />
      <BlogCard postsCards={blogPopularPosts} />
      <Footer />
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </>
  )
}

export default Landing
