import { ContentContext } from '@components/layouts'
import React, { useContext, useEffect } from 'react'
import { FaGlobeAfrica } from '@react-icons/all-files/fa/FaGlobeAfrica'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import toast, { Toaster } from 'react-hot-toast'

interface Props {
  postsCards: JSX.Element[]
}

const BlogCard: React.FC<Props> = ({ postsCards }) => {
  const { lang, translate, setLanguage } = useContext(ContentContext)

  return (
    <div className="container" id="blog">
      <div className="p-lg-12 p-md-8 mb-0">
        <div className="row text-center">
          <div className="col-xl-11 col-xxl-9 mx-auto mt-3">
            <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3 font-weight-bold">
              {translate('our_blog')}
            </h2>
          </div>
        </div>
        <div className="px-2 p-12">
          <div className="row gx-md-8 gx-xl-12 gy-10">
            <div className="container col-12 col-lg-7 col-md-12 col-sm-12 p-3 row row-cols-1 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
              {postsCards != null &&
                postsCards.length > 0 &&
                postsCards.map((posts, index) => (
                  <div key={index} className="mt-2">
                    {posts}
                  </div>
                ))}
            </div>
            <div className="col-lg-5">
              <h2 className="display-5 mb-3">{translate('blog_message')}</h2>
              <p className="lead mb-0 text-justify">
                {translate('blog_description')}
              </p>
              <a href="/blog">
                <button className="btn btn-outline-primary rounded-pill btn-send mt-2">
                  {translate('to_blog')}
                </button>
              </a>
            </div>
          </div>
          {/* /.row */}
        </div>
      </div>
    </div>
  )
}

export default BlogCard
